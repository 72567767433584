interface HeaderComponentProps {
    children: React.ReactNode;
}

export const HeaderComponent = ({ children }: HeaderComponentProps) => {
    return (
        <div className="fixed flex flex-row top-0 left-0 w-full py-2 px-6 h-[72px] justify-between items-center border-b border-[#E3E4E8] bg-white z-10 xl:px-72">
            {children}
        </div>
    )
}