import { ButtonComponent } from "../../components/button";
import checkCirculeIcon from "../../assets/checkCircle.svg";

interface CardSectionTowComponentProps {
}

const dataCardSectionTow = [
    {
        titulo: "Para vendedores",
        links: [
            { titulo: "Anunciar carro", link: "", icon: checkCirculeIcon },
            { titulo: "Alcance maior de compradores", link: "", icon: checkCirculeIcon },
            { titulo: "Negociação totalmente segura", link: "", icon: checkCirculeIcon },
        ]
    },
    {
        titulo: "Para concessionárias",
        links: [
            { titulo: "Acesso a um grande estoque de veículos", link: "", icon: checkCirculeIcon },
            { titulo: "Ferramentas para filtrar e comprar carros", link: "", icon: checkCirculeIcon },
            { titulo: "Processo de compra simplificado", link: "", icon: checkCirculeIcon },
        ]
    }
]

export const CardSectionTowComponent: React.FC<CardSectionTowComponentProps> = () => {
    return (
        <>
            {dataCardSectionTow.map((item, index) => (
                <div key={index} className="w-full flex flex-col justify-start items-start gap-4 p-6 bg-[#fefefe] rounded-[10px] border border-[#e3e4e8]">
                    <div className="text-[#1d2838] text-xl font-semibold leading-[30px]">{item?.titulo}</div>
                    <div className="flex flex-col justify-start items-start gap-3">
                        {item?.links.map((link, lickIndex) => (
                            <div key={`${lickIndex}-${index}`} className="justify-start items-center gap-2 inline-flex">
                                <div className="w-9 h-9 p-2 bg-[#eff5ff] rounded-[500px] justify-center items-center flex">
                                    <img alt="" src={link.icon} />
                                </div>
                                <div className="grow shrink basis-0 text-[#475466] text-base font-medium leading-normal">{link.titulo}</div>
                            </div>
                        ))}
                    </div>
                    <ButtonComponent size="full">
                        Quero vender meu carro
                    </ButtonComponent>
                </div>
            ))}
        </>
    )
}