export const TemplateEmailPinCodeComponent = () => {
    return (
        <>
            <div className="flex flex-col justify-center items-start gap-6 px-8">
                <div className="flex-col justify-start items-start gap-6 flex">
                    <span className="text-slate-800 text-2xl font-normal font-['Arial'] leading-loose">Você solicitou a recuperação de acesso!</span>
                    <span className="text-slate-700 text-base font-normal font-['Arial'] leading-normal">
                        Olá, Rodrigo Gómes
                    </span>
                    <span className="text-slate-700 text-base font-normal font-['Arial'] leading-normal">
                        Use o código PIN abaixo ao site para configurar sua nova senha.
                    </span>
                    <div className="text-slate-700 text-4xl font-bold font-['Arial']">6 1 0 3 6 7</div>
                    <span className="text-slate-700 text-base font-normal font-['Arial'] leading-normal">Obrigado,<br />Equipe Tem Proposta</span>
                </div>
                <div className="flex-col justify-start items-start gap-4 flex">
                    <div className="flex-col justify-start items-start gap-3 flex">
                        <div className="px-6 py-3 bg-blue-600 rounded-lg shadow justify-center items-center gap-2 inline-flex">
                            <div className="text-white text-sm font-medium font-['Poppins'] leading-tight">Configurar PIN</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}