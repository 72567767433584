export const Loading = () => {
    return (
        <div className="loading-container">
            <span className="dot-animation">
                <span>.</span>
                <span>.</span>
                <span>.</span>
            </span>
        </div>
    );
};
