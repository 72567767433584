import { CardSectionTowComponent } from "./card-section-tow";

export const CardSectionConfiancaComponent = () => {
    return (
        <div className="flex flex-col py-2 px-6 sm:py-16 sm:px-0 xl:px-72">
            {/* Card Confinça */}
            <div className="flex flex-col justify-start items-center gap-4">
                <div className="flex flex-col justify-start items-center gap-3">
                    <div className="h-6 px-2.5 py-0.5 bg-[#eff5ff] rounded-[150px] justify-center items-center gap-2.5 inline-flex">
                        <div className="text-center text-[#156fee] text-sm font-semibold leading-tight">Confiança</div>
                    </div>
                    <div className="text-center text-[#0f1728] text-3xl font-semibold leading-10">
                        Por que usar nosso sistema?
                    </div>
                </div>
            </div>

            {/* Card Para vendedores e Para concessionárias  */}
            <div className="w-full flex flex-col sm:flex-row justify-between gap-4 sm:py-8 sm:px-14">
                <CardSectionTowComponent />
            </div>
        </div>
    );
};