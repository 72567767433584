import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/logo.svg";
import { useState } from "react";
import { Loading } from "../../components/loading";

export const RedefinirSenha = () => {
    const navigator = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const handlerResetaSenha = () => {
        setIsLoading(true);
        setTimeout(() => {
            navigator('/redefinir-senha-success')
        }, 2000);
    }

    if (isLoading) {
        return <Loading />
    }

    return (
        <div className="w-full h-screen p-6 flex flex-col pt-8 pb-12 py-2 sm:px-6 items-center justify-end sm:justify-center gap-6 sm:bg-gray-50 bg-white">
            <div className="w-full sm:max-w-xl sm:p-2 md:p-1">
                <div className="flex flex-col sm:items-center gap-6">
                    <img src={logo} alt="vortex" className="w-[60px] h-[54px]" />
                    <div className="sm:text-center justify-start items-start gap-2">
                        <div className=" flex flex-col sm:flex-row sm:gap-1 text-[#1d2838] text-[40px] sm:text-[36px] font-black leading-[50px]">
                            Recuperar acesso
                        </div>
                        <p className="text-[#475466] text-sm">Siga os passos para recuperar seu acesso</p>
                    </div>
                </div>
            </div>
            <div className="w-full sm:max-w-xl sm:p-10 bg-white sm:rounded-lg sm:border sm:border-zinc-200">
                <div className="flex flex-col justify-start items-center sm:items-center gap-6">
                    <div className="w-full flex flex-col gap-6">
                        <div className="flex flex-col gap-1">
                            <label className="text-slate-800 text-sm font-medium">Email</label>
                            <input
                                type="email"
                                className="h-14 p-4 bg-white rounded-md border border-zinc-200 text-gray-500 text-sm"
                                placeholder="Digite seu email"
                            />
                        </div>
                        <div className="w-full">
                            <button onClick={handlerResetaSenha} className="h-12 w-full bg-blue-600 text-white text-sm font-medium rounded-lg flex justify-center items-center">
                                Recuperar acesso
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="self-stretch justify-center items-center gap-1 inline-flex">
                <div className="text-[#1d2838] text-sm font-normal leading-[21px]">Já possui uma conta?</div>
                <div className="justify-start items-start flex">
                    <div className="justify-center items-center gap-2 flex">
                        <Link to={'/login'} className="text-[#156fee] text-sm font-medium underline leading-[21px]">Acesse aqui</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}