import { CardComponent } from "../../components/card";

interface CardSectionComponentProps {
    icon: string;
    titulo: string;
    descricao: string;
}

export const CardSectionComponent = ({ icon, titulo, descricao }: CardSectionComponentProps) => {
    return (
        <CardComponent>
            <div className="w-10 h-10 p-[8.33px] bg-[#eff8ff] rounded-xl justify-center items-center inline-flex">
                <div className="w-[23.33px] h-[23.33px] relative flex-col justify-start items-start flex">
                    <img alt="" src={icon} />
                </div>
            </div>
            <div className="flex-col justify-start items-start gap-2 flex">
                <div className="text-[#1d2838] text-xl font-semibold leading-[30px]">
                    {titulo}
                </div>
                <div className="text-[#475466] text-base font-medium leading-normal">
                    {descricao}
                </div>
            </div>
        </CardComponent>
    )
}