import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/logo.svg";
import { Loading } from "../../components/loading";
import { useState } from "react";
export const Login = () => {
    const navigator = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const handlerSubmit = () => {
        setIsLoading(true);
        setTimeout(() => {
            navigator('/')
        }, 2000);
    }

    if (isLoading) {
        return <Loading />
    }

    return (
        <div className="w-full h-screen p-6 flex flex-col pt-8 pb-12 py-2 sm:px-6 items-center justify-end sm:justify-center gap-8 sm:bg-gray-50 bg-white">
            <div className="w-full sm:max-w-xl sm:p-6 md:p-1">
                <div className="flex flex-col sm:items-center gap-6">
                    <img src={logo} alt="vortex" className="w-[60px] h-[54px] rotate-180" />
                    <div className="sm:text-center justify-start items-start gap-2">
                        <div className=" flex flex-col sm:flex-row sm:gap-1 text-[#1d2838] text-[40px] sm:text-[32px] font-black leading-[50px]">
                            Bem-vindo ao
                            <span className="text-blue-600 font-black">Tem Proposta</span>
                        </div>
                        <p className="text-[#475466] text-sm">Insira suas informações para acessar sua conta</p>
                    </div>
                </div>
            </div>
            <div className="w-full sm:max-w-xl sm:p-10 md:p-10 bg-white sm:rounded-lg sm:border sm:border-zinc-200">
                <div className="flex flex-col justify-start items-center sm:items-center gap-6">
                    {/* Formulário */}
                    <div className="w-full flex flex-col gap-4 sm:gap-6">
                        <div className="flex flex-col gap-1">
                            <label className="text-slate-800 text-sm font-medium">Email</label>
                            <input
                                type="email"
                                className="h-14 p-4 bg-white rounded-md border border-zinc-200 text-gray-500 text-sm"
                                placeholder="Digite seu email"
                            />
                        </div>
                        <div className="flex flex-col gap-1">
                            <label className="text-slate-800 text-sm font-medium">Senha</label>
                            <input
                                type="password"
                                className="h-14 p-4 bg-white rounded-md border border-zinc-200 text-gray-500 text-sm"
                                placeholder="Digite sua senha"
                            />
                        </div>

                        {/* Permanecer conectado e Esqueci a senha */}
                        <div className="self-stretch justify-between items-center inline-flex">
                            <div className="justify-start items-center gap-2 flex">
                                <div className="w-5 h-5 justify-center items-center flex">
                                    <input
                                        type="checkbox"
                                        className="w-5 h-5 relative rounded-lg border-2 border-[#e3e4e8] cursor-pointer"
                                        id="rememberMe"
                                    />
                                </div>
                                <label htmlFor="rememberMe" className="text-[#1d2838] text-sm font-medium leading-tight cursor-pointer">
                                    Permanecer conectado
                                </label>
                            </div>

                            <div className="justify-center items-center gap-2 flex">
                                <Link to="/redefinir-senha" className="text-[#156fee] text-sm font-semibold leading-tight">
                                    Esqueci a senha
                                </Link>
                            </div>
                        </div>

                        {/* Botão de Login */}
                        <div className="w-full">
                            <button onClick={handlerSubmit} className="h-12 w-full bg-blue-600 text-white text-sm font-medium rounded-lg flex justify-center items-center">
                                Login
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="self-stretch flex justify-center items-center gap-1">
                <div className="text-[#1d2838] text-sm font-normal leading-[21px]">Ainda não tem uma conta?</div>
                <div className="justify-start items-start flex">
                    <Link to={'/cadastro'} className="justify-center items-center gap-2 flex">
                        <span className="text-[#156fee] text-sm font-medium underline leading-[21px]">Crie uma aqui</span>
                    </Link>
                </div>
            </div>
        </div>
    )
}