import { useState } from "react";
import addCircleIcon from "../../assets/addCircle.svg";
import minusCircleIcon from "../../assets/minuCircle.svg";

const FaqItem = ({ title }: { title: string }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleContent = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className="w-full flex flex-col p-4 gap-4 rounded-lg border border-[#e3e4e8]">
            <div className="grow shrink basis-0 h-7 justify-start items-center gap-4 flex">
                <div className="grow shrink basis-0 text-[#1d2838] text-lg font-medium leading-7">{title}</div>
                <div className="w-6 h-6 relative cursor-pointer" onClick={toggleContent}>
                    <img alt="" src={isExpanded ? minusCircleIcon : addCircleIcon} />
                </div>
            </div>
            {isExpanded && (
                <div className="tap-content">
                    Lorem ipsum dolor sit amet consectetur. Mi pretium et ac vivamus.
                    Parturient aenean pulvinar ut volutpat blandit rhoncus ut elementum.
                    Enim eget ut pellentesque amet malesuada at amet aliquam amet.
                </div>
            )}
        </div>
    );
};

export const CardFaqComponent = () => {
    return (
        <div className="flex flex-col justify-start items-center px-6 py-8 bg-[#fefefe] sm:px-14 sm:py-16 xl:px-72">
            <div className="flex flex-col justify-start items-center gap-6">

                <div className="flex flex-col justify-center items-center gap-4 py-6">
                    <div className="flex flex-col justify-start items-center gap-3">
                        <div className="flex flex-col justify-start items-center gap-3">
                            <div className="flex justify-center items-center gap-2.5 px-2.5 py-0.5 bg-[#eff5ff] rounded-[150px]">
                                <div className="text-center text-[#156fee] text-sm font-semibold leading-tight">FAQ</div>
                            </div>
                            <div className="text-center text-[#1d2838] text-[28px] font-semibold">Perguntas que sempre nos fazem</div>
                        </div>
                    </div>
                    <div className="text-center text-[#475466] text-base font-normal">
                        Tentamos responder às perguntas mais comuns. Se tiver alguma dúvida <br className="sm:block" />
                        adicional, entre em contato com nossa equipe amigável.
                    </div>
                </div>

                <div className="flex flex-col justify-start items-start gap-3 sm:w-[800px]">
                    <FaqItem title="01. Lorem ipsum?" />
                    <FaqItem title="02. Consectetur et ac vivamus blandit?" />
                    <FaqItem title="03. Rhoncus ut elementum enim?" />
                    <FaqItem title="04. Enim egt ut pellentesque amet malesuada?" />
                    <FaqItem title="05. Lorem ipsum dolor sit amet consectetur?" />
                </div>

                <div className="flex flex-col justify-start items-center gap-8 p-4 bg-sky-50 rounded-[20px] sm:p-8 sm:w-[530px]">
                    <div className="flex-col justify-start items-center gap-3 flex">
                        <div className="text-center text-[#194084] text-xl font-normal leading-[30px]">Ainda tem dúvidas?</div>
                        <div className="text-center text-[#194084] text-sm font-normal leading-normal">Desculpe por não tirar todas as dúvidas que esteja procurando aqui. Por favor, entre em contato conosco e estaremos felizes em te ajudar.</div>
                    </div>
                    <div className="flex-col justify-start items-start gap-3 flex">
                        <div className="px-6 py-4 bg-[#156fee] rounded-lg shadow justify-center items-center gap-2 inline-flex">
                            <div className="text-[#fefefe] text-sm font-medium leading-[21px]">Nos chame no WhatsApp</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}