import type { ComponentProps, ReactNode } from "react";
import { tv, VariantProps } from 'tailwind-variants'

const buttonVariants = tv({
    base: 'flex justify-center items-center gap-2 rounded-lg',
    variants: {
        variant: {
            primary: 'bg-[#156fee] text-[#fefefe] text-sm font-medium leading-[21px]',
            secondary: 'border-none border-zinc-200 font-medium',
        },
        size: {
            default: 'h-12 px-6 py-3',
            full: 'self-stretch w-full h-14 px-6 py-4'
        },
        hidde: {
            default: 'hidden sm:block',
            hidden: 'hidden'
        },
    },
    defaultVariants: {
        variant: 'primary',
        size: 'default'
    }
})

interface ButtonProps extends ComponentProps<'button'>, VariantProps<typeof buttonVariants> {
    children: ReactNode,
}

export const ButtonComponent = ({ children, variant, size, hidde, ...props }: ButtonProps) => {
    return (
        <button {...props} className={buttonVariants({ variant, size, hidde })}>
            {children}
        </button>
    )
}