import logo from "../../assets/logo.svg";
import { Loading } from "../../components/loading";
import { useState } from "react";
import { MessageComponent } from "../../components/message";

export const NovaSenhaPage = () => {


    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const handlerSubmit = () => {
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
            setIsSuccess(true)
        }, 2000);
    }

    if (isLoading) {
        return <Loading />
    }

    return (
        isSuccess ? (<MessageComponent
            titulo="Agora sim!"
            subTitulo="Sua nova senha foi criada com sucesso! Retorne ao login para acessar o sistema."
            labelButton="Acessar sistema"
            toBack="/login" />
        ) : (


            <div className="w-full h-screen p-6 flex flex-col pt-8 pb-12 py-2 sm:px-6 items-center justify-end sm:justify-center gap-8 sm:bg-gray-50 bg-white">
                <div className="w-full sm:max-w-xl sm:p-2 md:p-1">
                    <div className="flex flex-col sm:items-center gap-6">
                        <img src={logo} alt="vortex" className="w-[60px] h-[54px]" />
                        <div className="sm:text-center justify-start items-start gap-2">
                            <div className=" flex flex-col sm:flex-row sm:gap-1 text-[#1d2838] text-[40px] sm:text-[36px] font-black leading-[50px]">
                                Nova Senha
                            </div>
                            <p className="text-[#475466] text-sm">
                                Crie uma nova senha. Não esqueça de adicionar um caracter maiúsculo, números e caracteres especiais.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="w-full sm:max-w-xl sm:p-10 md:p-10 bg-white sm:rounded-lg sm:border sm:border-zinc-200">
                    <div className="flex flex-col justify-start items-center sm:items-center gap-6">
                        {/* Formulário */}
                        <div className="w-full flex flex-col gap-4 sm:gap-6">
                            <div className="flex flex-col gap-1">
                                <label className="text-slate-800 text-sm font-medium">Nova senha</label>
                                <input
                                    type="password"
                                    className="h-14 p-4 bg-white rounded-md border border-zinc-200 text-gray-500 text-sm"
                                />
                            </div>
                            <div className="flex flex-col gap-1">
                                <label className="text-slate-800 text-sm font-medium">Repetir nova senha</label>
                                <input
                                    type="password"
                                    className="h-14 p-4 bg-white rounded-md border border-zinc-200 text-gray-500 text-sm"
                                />
                            </div>

                            {/* Botão de Login */}
                            <div className="w-full">
                                <button onClick={handlerSubmit} className="h-12 w-full bg-blue-600 text-white text-sm font-medium rounded-lg flex justify-center items-center">
                                    Criar nova senha
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    )
}