import type { ComponentProps } from "react";

interface InputLabelProps extends ComponentProps<'input'> {
    labelTitulo: string,
}

export const InputLabel = ({ labelTitulo, ...props }: InputLabelProps) => {
    return (
        <div className="flex flex-col gap-1">
            <label className="text-slate-800 text-sm font-medium">{labelTitulo}</label>
            <input
                {...props}
                className="h-14 p-4 bg-white rounded-md border border-zinc-200 text-gray-500 text-sm focus:ring-2 focus:ring-blue-600 focus:outline-none"
            />
        </div>
    )
};