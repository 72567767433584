import newsletterImage from "../../assets/bg-newletters.png";
import { ButtonComponent } from "../../components/button";

export const CardNewsletterComponent = () => {
    return (
        <div className="flex flex-col justify-start items-center w-full gap-4 bg-[#eff8ff] sm:bg-[#fefefe] sm:p-16 xl:px-72">
            <div
                className="w-full flex flex-col justify-start items-start gap-6 bg-[#eff8ff] sm:bg-no-repeat sm:bg-right sm:bg-contain py-2 px-6 sm:rounded-[32px] sm:p-16"
                style={{ backgroundImage: `url(${newsletterImage})` }}
            >
                <div className="flex flex-col gap-6 sm:flex sm:flex-col sm:w-[692px] sm:gap-6">
                    <div className="flex-col justify-start items-start gap-4 pt-4 flex">
                        <div className="text-[#194084] text-[28px] font-semibold leading-9">
                            Cadastre seu e-mail e fique por dentro de novidades
                        </div>
                        <div className="text-[#344053] text-base font-normal leading-normal">
                            Fique por dentro das informações mais relevantes, nossas novidades, notícias e ofertas especiais!
                        </div>
                    </div>
                    <div className="flex flex-col justify-start gap-3 sm:items-end sm:flex-row sm:gap-3">
                        <div className="flex flex-1">
                            <input className="h-14 pl-3 pr-2 py-2.5 grow shrink basis-0 text-[#475466] text-base font-normal leading-normal bg-[#fefefe] rounded-lg border border-[#e3e4e8] justify-start items-center gap-2 flex flex-1 focus:ring-2 focus:ring-blue-600 focus:outline-none" placeholder="Seu e-mail" />
                        </div>
                        <div>
                            <ButtonComponent size="full">
                                Quero vender meu carro
                            </ButtonComponent>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

