import { Link } from "react-router-dom";
import logo from "../../assets/logo.svg";
import { Loading } from "../../components/loading";
import { useState } from "react";
import { InputLabel } from "../../components/input-label";
import { MessageComponent } from "../../components/message";

export const Cadastro = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const handlerSubmit = () => {
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
            setIsSuccess(true)
        }, 2000);
    }

    if (isLoading) {
        return <Loading />
    }

    return (
        isSuccess ? (<MessageComponent
            titulo="Agora sim!"
            subTitulo="Seu cadastro foi realizado com sucesso! Comece agora a vender seu automóvel e receba as melhores ofertas por ele."
            labelButton="Acessar sistema"
            toBack="/login" />
        ) : (
            <div className="w-full h-screen flex flex-col pt-8 pb-8 p-6 items-center justify-end gap-8  sm:h-full sm:px-6 sm:justify-center sm:bg-gray-50 bg-white">

                <div className="flex flex-col gap-6 sm:items-center">
                    <img src={logo} alt="temproposta" className="w-[60px] h-[54px]" />
                    <div className="justify-start items-start gap-2 sm:justify-center sm:items-center sm:text-center">
                        <span className="text-[#1d2838] text-[40px] sm:text-[36px] font-black leading-[50px] sm:text-center text-left">
                            Cadastro
                        </span>
                        <p className="text-[#475466] text-sm">Crie o seu acesso ao sistema e comece a receber ofertas</p>
                    </div>
                </div>

                <div className="w-full sm:max-w-xl sm:p-10 md:p-10 bg-white sm:rounded-lg sm:border sm:border-zinc-200">
                    <div className="flex flex-col justify-start items-center sm:items-center gap-6">
                        {/* Formulário */}
                        <div className="w-full flex flex-col gap-4">

                            <InputLabel labelTitulo="Nome Completo" type="text" />
                            <InputLabel labelTitulo="Email" type="text" />
                            <InputLabel labelTitulo="Celular" type="text" placeholder="Ex. (xx) 9xxxx-xxxx" />
                            <InputLabel labelTitulo="Senha" type="password" />
                            <InputLabel labelTitulo="Confirmar Senha" type="password" />

                            {/* Botão de Cadastrar */}
                            <div className="w-full">
                                <button onClick={handlerSubmit} className="h-12 w-full bg-blue-600 text-white text-sm font-medium rounded-lg flex justify-center items-center">
                                    Criar acesso
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="self-stretch flex justify-center items-center gap-1">
                    <div className="text-[#1d2838] text-sm font-normal leading-[21px]">Já possui uma conta?</div>
                    <div className="justify-start items-start flex">
                        <Link to={'/login'} className="justify-center items-center gap-2 flex">
                            <span className="text-[#156fee] text-sm font-medium underline leading-[21px]">Faça login aqui</span>
                        </Link>
                    </div>
                </div>
            </div>
        )
    )
}