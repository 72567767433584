import { HeaderComponent } from "../../components/header"
import { ButtonComponent } from "../../components/button";
import menuIcon from "../../assets/menu.svg";
import logo from "../../assets/logo.svg";

import { useState } from "react";
import { Sidebar } from "../../components/sidebar";
import { FooterComponent } from "../../components/footer";
import { CardSectionComunidadeComponent } from "./card-section-comunidade";
import { CardSectionHeroComponent } from "./card-section-hero";
import { CardSectionConfiancaComponent } from "./card-section-confianca";
import { CardFaqComponent } from "./card-section-faq";
import { CardNewsletterComponent } from "./card-section-newletter";
import { useNavigate } from "react-router-dom";
export const Home = () => {
    const navigate = useNavigate();

    // Estado para controlar a visibilidade do drawer
    const [isDrawerOpen, setDrawerOpen] = useState<boolean>(false);

    // Função para abrir e fechar o drawer
    const toggleDrawer = () => {
        setDrawerOpen((prevState) => !prevState);
    };

    const handlerLogin = () => {
        navigate('/login');
    }

    return (
        <div className="h-screen w-full bg-[#f8f9fb]">

            <HeaderComponent>
                <div className="flex gap-4 justify-center items-center sm:gap-2">
                    <button
                        data-drawer-target="drawer-navigation"
                        data-drawer-toggle="drawer-navigation"
                        aria-controls="drawer-navigation"
                        onClick={toggleDrawer}>
                        <img src={menuIcon} alt="vortex" className="w-6 h-6 relative block sm:hidden" />
                        <img src={logo} alt="vortex" className="w-[35px] h-[32px] rotate-180 hidden sm:block" />
                    </button>
                    <span className="font-semibold text-xl sm:text-2xl text-[#1D2939]">Tem Proposta</span>
                </div>
                <div className="flex flex-row gap-4">
                    <ButtonComponent variant="secondary" hidde="default" onClick={handlerLogin} >
                        Acessar Plataforma
                    </ButtonComponent>
                    <ButtonComponent>
                        Quero vender
                    </ButtonComponent>
                </div>
                <Sidebar id="side-menu" isOpen={isDrawerOpen} closeSidebar={toggleDrawer} />
            </HeaderComponent >

            <div className="section flex flex-col mt-[72px] gap-8 h-full sm:gap-0">
                {/* Card Hero */}
                <CardSectionHeroComponent />

                {/* Card Comodidade */}
                <CardSectionComunidadeComponent />

                {/* Newsletter */}
                <CardNewsletterComponent />

                {/* Proque Confinça */}
                <CardSectionConfiancaComponent />

                {/* FAQ */}
                <CardFaqComponent />

                {/* Footer */}
                <FooterComponent />
            </div>
        </div>
    )
}