import logo from "../../assets/logo.svg";
import instagramIcon from "../../assets/icon/instagram.svg";
import facebookIcon from "../../assets/icon/facebook.svg";
import twitterIcon from "../../assets/icon/x.svg";
import linkedinIcon from "../../assets/icon/linkedin.svg";
import type React from "react";
import { TemplateEmailPinCodeComponent } from "./pin-code";

export const TemplateEmail: React.FC = () => {

    return (
        <div className="w-full flex max-w-xl flex-col justify-start items-start gap-6 p-6">

            <div className="w-[600px] flex py-6 justify-between items-center gap-2">
                <div className="flex justify-start items-center gap-2 flex-1">
                    <img src={logo} alt="log" />
                    <span className="text-center text-slate-800 text-2xl font-semibold font-['Poppins'] leading-7">Tem Proposta</span>
                </div>
                <div className="justify-end items-center gap-2 flex flex-1">
                    <div className="flex flex-row justify-center items-center gap-4 sm:justify-start">
                        <div className="w-8 h-8 relative sm:w-6 sm:h-6">
                            <img src={instagramIcon} alt="instagram" className="w-8 h-8 left-[2px] top-[2px] absolute rounded-md sm:w-6 sm:h-6" />
                        </div>
                        <div className="w-8 h-8 relative sm:w-6 sm:h-6">
                            <img src={facebookIcon} alt="linkedin" className="w-8 h-8 left-[2px] top-[2px] absolute rounded-full sm:w-6 sm:h-6" />
                        </div>
                        <div className="w-8 h-8 relative sm:w-6 sm:h-6">
                            <img src={twitterIcon} alt="twitter" className="w-8 h-8 left-[2px] top-[2px] absolute sm:w-6 sm:h-6" />
                        </div>
                        <div className="w-8 h-8 relative sm:w-6 sm:h-6">
                            <img src={linkedinIcon} alt="linkedin" className="w-8 h-8 left-[2px] top-[2px] absolute rounded-full sm:w-6 sm:h-6" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-[600px] flex flex-col justify-center  py-8 bg-white rounded-lg gap-2.5">
                <TemplateEmailPinCodeComponent />
            </div>

            <div className="h-72 pt-6 pb-12 bg-white/0 flex-col justify-center items-start gap-6 flex">
                <div className="flex-col justify-start items-start gap-6 flex">
                    <div className="text-slate-800 text-base font-normal font-['Inter'] leading-normal">Você recebeu este e-mail porque se cadastrou para receber informações sobre o Tem Proposta.</div>
                    <div className="flex flex-col gap-6">

                        <p className="text-slate-800 text-base font-normal font-['Arial'] leading-normal gap-1 flex">
                            Acesse nossa Política de Privacidade.
                            <span className="text-blue-600 text-base font-normal font-['Arial'] leading-normal">[LINK PARA PÁGINA]</span>
                        </p>

                        <p className="text-slate-800 text-base font-normal font-['Arial'] leading-normal">
                            Se não deseja receber mais mensagens como essa,
                            <span className="text-blue-600 text-base font-normal font-['Arial'] underline leading-normal">clique aqui</span>
                        </p>
                        <p className="text-slate-800 text-base font-normal font-['Arial'] leading-normal flex flex-col">
                            E ndereço vem aqui
                            <span>©  2024 Tem Proposta</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}