import { ButtonComponent } from "../../components/button";
import underlineImage from "../../assets/underline.svg";
import galleryImage from "../../assets/_galleryFeaturesBase.svg";

export const CardSectionHeroComponent = () => {
    return (
        <div className="flex flex-col py-2 px-6 justify-start items-start sm:gap-8 bg-[#fefefe] sm:justify-between sm:items-center sm:flex-row sm:p-14 xl:px-72">
            <div className="flex flex-col justify-start items-start gap-4 sm:flex-1 xl:flex-1">
                <div className="relative text-[#141c24] text-[40px] font-light leading-[45px]">
                    Tudo que você precisa para<br />vender seu carro
                    <img src={underlineImage} alt="underline" className="absolute bottom-[-5px] left-0 w-auto h-auto" />
                </div>
                <div className="text-[#475466] text-xl font-normal leading-[30px]">
                    Lorem ipsum dolor sit amet consectetur. Nulla et velit et odio leo amet fringilla ut quisque
                </div>
                <div className="flex flex-col justify-start items-start gap-3">
                    <ButtonComponent hidde="default">
                        Quero vender meu carro
                    </ButtonComponent>
                </div>
            </div>
            <div className=" flex flex-col justify-start items-start gap-4 sm:flex-1 xl:flex-1 xl:items-end">
                <img alt="img1" className="relative rounded-lg sm:w-[566px] sm:h-[438px] xl:w-[566px] xl:h-[438px] sm:rounded-2xl" src={galleryImage} />
                <div className="h-14 w-full flex flex-col  justify-start items-start gap-3 sm:hidden">
                    <ButtonComponent size="full">
                        Quero vender meu carro
                    </ButtonComponent>
                </div>
            </div>
        </div>
    );
};