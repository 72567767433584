
import instagramIcon from "../../assets/instagram.svg";
import facebookIcon from "../../assets/facebook.svg";
import twitterIcon from "../../assets/twitter.svg";
import linkedinIcon from "../../assets/linkedin.svg";
import emailIcon from "../../assets/email.svg";
import phoneIcon from "../../assets/phone.svg";
import locationIcon from "../../assets/location.svg";
import logo from "../../assets/logo.svg";
import { Divider } from "../divider";

export const FooterComponent = () => {
    return (
        <div className="flex flex-col justify-between items-center px-6 gap-8 sm:p-10 xl:px-72 py-8">
            <div className="w-full flex flex-col gap-8 justify-between sm:justify-between sm:flex sm:flex-row">
                <div className="w-full flex flex-col gap-8 sm:gap-2">
                    <div className="w-full flex flex-col justify-start items-center gap-6 sm:items-start">
                        <div className="flex justify-center items-center gap-2 sm:justify-start">
                            <img src={logo} alt="vortex" className="w-11 h-10" />
                            <div className="text-center text-[#1d2838] text-2xl font-semibold">Tem Proposta</div>
                        </div>
                        <div className="text-center text-[#475466] text-base font-normal leading-normal sm:text-left">
                            Adoramos trabalhar com pessoas.<br />
                            Vamos construir algo grande juntos.
                        </div>
                    </div>
                    <div className="flex flex-row justify-center items-center gap-4 sm:justify-start">
                        <div className="w-8 h-8 relative sm:w-6 sm:h-6">
                            <img src={instagramIcon} alt="instagram" className="w-8 h-8 left-[2px] top-[2px] absolute bg-[#98a1b2] rounded-md sm:w-6 sm:h-6" />
                        </div>
                        <div className="w-8 h-8 relative sm:w-6 sm:h-6">
                            <img src={facebookIcon} alt="linkedin" className="w-8 h-8 left-[2px] top-[2px] absolute bg-[#98a1b2] rounded-full sm:w-6 sm:h-6" />
                        </div>
                        <div className="w-8 h-8 relative sm:w-6 sm:h-6">
                            <img src={twitterIcon} alt="twitter" className="w-8 h-8 left-[2px] top-[2px] absolute sm:w-6 sm:h-6" />
                        </div>
                        <div className="w-8 h-8 relative sm:w-6 sm:h-6">
                            <img src={linkedinIcon} alt="linkedin" className="w-8 h-8 left-[2px] top-[2px] absolute bg-[#98a1b2] rounded-full sm:w-6 sm:h-6" />
                        </div>
                    </div>
                </div>
                <div className="w-full flex flex-col justify-start items-start gap-8">
                    <div className="flex flex-col justify-start items-start gap-4 ">
                        <div className="text-center text-[#1d2838] text-lg font-semibold leading-7">Fale conosco</div>
                        <div className="flex flex-col justify-center items-start gap-4 ">
                            <div className="flex  justify-center items-center gap-2 rounded-lg border-[#ced2da]">
                                <img src={emailIcon} alt="email" className="w-5 h-5 relative" />
                                <div className="grow shrink basis-0 text-[#475466] text-base font-normal leading-normal">cafezinho@temproposta.com.br</div>
                            </div>
                            <div className="flex justify-center items-center gap-2 rounded-lg border-[#ced2da]">
                                <img src={phoneIcon} alt="phone" className="w-5 h-5 relative" />
                                <div className="grow shrink basis-0 text-[#475466] text-base font-normal leading-normal">+55 (11) 9.0000-0000</div>
                            </div>
                            <div className="flex justify-start items-start gap-2 rounded-lg border-[#ced2da]">
                                <img src={locationIcon} alt="location" className="w-5 h-5 relative" />
                                <div className="grow shrink basis-0 text-[#475466] text-base font-normal leading-normal">Av. Dr. Lorem Ipusm, 5000 • Vila Andrade • São Paulo/SP</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Divider />
            <div className="w-full flex flex-col justify-start items-center gap-3 sm:flex-row sm:justify-between">
                <div className="text-[#1d2838] text-sm sm:text-base font-normal leading-normal">© 2024 Vortéx • Todos os direitos reservados</div>
                <div className="flex justify-start items-center gap-4">
                    <div className="text-right text-[#156fee] text-base font-normal underline leading-normal">Termos de uso</div>
                    <div className="h-5 border-l border-gray-400"></div> {/* Barra vertical */}
                    <div className="text-right text-[#156fee] text-base font-normal underline leading-normal">Política de privacidade</div>
                </div>
            </div>
        </div>
    )
}