import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../components/loading";
import logo from "../../assets/logo.svg";

interface PinCodeInputComponentProps {
    index: number;
    inputRefs: React.MutableRefObject<(HTMLInputElement | null)[]>;
    pinValues: string[];
    setPinValues: React.Dispatch<React.SetStateAction<string[]>>;
}

const PinCodeIpuntComponent = ({ index, inputRefs, pinValues, setPinValues }: PinCodeInputComponentProps) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        // Permitir apenas um único número no input
        if (value.length === 1) {
            const value = e.target.value;

            if (value.length === 1 && inputRefs.current[index + 1]) {
                inputRefs.current[index + 1]?.focus();
            }

            if (value.length > 1) {
                e.target.value = value.slice(0, 1);
            }

            const updatedPinValues = [...pinValues];
            updatedPinValues[index] = value;

            console.log(updatedPinValues);

            setPinValues(updatedPinValues);
        } else {
            e.target.value = value.charAt(0); // Se o usuário tentar inserir mais de um caractere, pega apenas o primeiro
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        // Se a tecla "Backspace" for pressionada e o campo estiver vazio, mova o foco para o campo anterior
        if (e.key === "Backspace" && !e.currentTarget.value && inputRefs.current[index - 1]) {
            inputRefs.current[index - 1]?.focus();
        }
    };

    return (
        <input
            ref={(el) => (inputRefs.current[index] = el)} // index tipado como number
            type="text" // Alterado para texto, para evitar comportamento inesperado de inputs tipo number
            inputMode="numeric" // Ainda permite apenas números no teclado
            pattern="[0-9]*" // Permite apenas números
            maxLength={1} // Limitar a entrada a um único caractere
            className="h-14 p-4 col-span-1 bg-white rounded-md border border-zinc-200 text-gray-800 text-xl font-bold text-center focus:ring-2 focus:ring-blue-600 focus:outline-none"
            onChange={handleChange}
            onKeyDown={handleKeyDown} // Captura o backspace para mover o foco para o campo anterior
        />
    );
};


export const PinCode = () => {
    const inputRefs = useRef<(HTMLInputElement | null)[]>([]); // Inicializa as referências de inputs
    const navigate = useNavigate();
    const [pinValues, setPinValues] = useState<string[]>(Array(6).fill(""));
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = () => {
        const pinCode = pinValues.join("");
        console.log("PIN enviado para API:", pinCode);

        // Simulação de chamada à API
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
            navigate("/nova-senha"); // Navega para a próxima página após 2 segundos
        }, 2000);
    };

    // Usando useEffect para monitorar mudanças em pinValues
    useEffect(() => {
        if (pinValues.every(value => value !== "")) {
            handleSubmit(); // Chama o submit quando todos os campos estão preenchidos
        }
    }, [pinValues]); // Executa o efeito quando o estado pinValues é atualizado


    if (isLoading) {
        return <Loading />;
    }

    return (
        <div className="w-full h-screen p-6 flex flex-col pt-8 pb-12 py-2 sm:px-6 items-center justify-end sm:justify-center gap-6 sm:bg-gray-50 bg-white">
            <div className="w-full sm:max-w-xl sm:p-2 md:p-1">
                <div className="flex flex-col sm:items-center gap-6">
                    <img src={logo} alt="vortex" className="w-[60px] h-[54px]" />
                    <div className="sm:text-center justify-start items-start gap-2">
                        <div className=" flex flex-col sm:flex-row sm:gap-1 text-[#1d2838] text-[40px] sm:text-[36px] font-black leading-[50px]">
                            PIN
                        </div>
                        <p className="text-[#475466] text-sm">
                            Adicione o PIN para criar uma nova senha
                        </p>
                    </div>
                </div>
            </div>
            <div className="w-full sm:max-w-xl sm:p-10 bg-white sm:rounded-lg sm:border sm:border-zinc-200">
                <div className="flex flex-col justify-start items-center sm:items-center gap-6">
                    <div className="w-full flex flex-col gap-6">
                        <div className="grid grid-cols-6 flex-col gap-3">
                            {[...Array(6)].map((_, index: number) => ( // Tipando o index como number
                                <PinCodeIpuntComponent
                                    key={index}
                                    index={index}
                                    inputRefs={inputRefs}
                                    pinValues={pinValues}
                                    setPinValues={setPinValues} />
                            ))}
                        </div>
                        <div className="w-full">
                            <button
                                onClick={handleSubmit}
                                className="h-12 w-full bg-blue-600 text-white text-sm font-medium rounded-lg flex justify-center items-center"
                            >
                                Continuar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
