import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { Home } from './pages/home';
import './App.css'; // Arquivo de CSS com as animações
import { Login } from './pages/login';
import { useState, useEffect } from 'react';
import { MessageRedefinirSenha } from './pages/login/message-redefinir-senha';
import { Cadastro } from './pages/cadastro';
import { TemplateEmail } from './pages/template-email';
import { PoliticaDePrivacidade } from './pages/politica-de-privacidade';
import { TermosDeUsos } from './pages/termo-de-uso';
import { RedefinirSenha } from './pages/login/redefinir-senha';
import { PinCode } from './pages/login/pin-code';
import { NovaSenhaPage } from './pages/login/nova-senha';

function AnimatedRoutes() {
    const location = useLocation();
    const [animate, setAnimate] = useState(true);

    useEffect(() => {
        setAnimate(true); // Ativa a animação ao trocar de rota
    }, [location]);

    return (
        <div className={`page ${animate ? 'animate-in' : 'animate-out'}`}>
            <Routes location={location}>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/redefinir-senha" element={<RedefinirSenha />} />
                <Route path="/cadastro" element={<Cadastro />} />
                <Route path="/redefinir-senha-success" element={<MessageRedefinirSenha />} />
                <Route path="/template-email" element={<TemplateEmail />} />
                <Route path="/termos-de-usos" element={<TermosDeUsos />} />
                <Route path="/politica-de-privacidade" element={<PoliticaDePrivacidade />} />
                <Route path="/pin-code" element={<PinCode />} />
                <Route path="/nova-senha" element={<NovaSenhaPage />} />
            </Routes>
        </div>
    );
}

export default function App() {
    return (
        <BrowserRouter basename="/">
            <AnimatedRoutes />
        </BrowserRouter>
    );
}
