import mobileIcon from "../../assets/Mobile.svg";
import houseGroupIcon from "../../assets/houseGroup.svg";
import checkedIcon from "../../assets/checked.svg";
import rectagle145Image from "../../assets/rectangle145.svg";
import rectagle146Image from "../../assets/rectangle146.svg";
import rectagle147Image from "../../assets/rectangle147.svg";
import rectagle148Image from "../../assets/rectangle148.svg";
import { CardSectionComponent } from "./card-section";

export const CardSectionComunidadeComponent = () => {
    return (
        <div className="flex flex-col gap-4 py-2 px-6 sm:px-14 sm:py-16 xl:px-72">
            
            <div className="flex flex-col justify-start items-center gap-4">
                <div className="flex-col justify-start items-center gap-3 flex sm:gap-2">
                    <div className="flex justify-center items-center gap-2.5 px-2.5 py-0.5 bg-[#eff5ff] rounded-[150px]">
                        <div className="text-center text-[#156fee] text-sm font-semibold leading-tight">Comodidade</div>
                    </div>
                    <div className="text-center text-[#0f1728] text-3xl font-semibold leading-10">Como o processo todo funciona</div>
                </div>
                <div className="text-center text-[#475466] text-lg font-normal">Receba as melhores ofertas se conectando com concessionárias em um só lugar.</div>
            </div>

            <div className="flex flex-col gap-4 justify-between sm:flex-row sm:justify-between sm:items-center">
                <div className="flex flex-col gap-4 sm:flex-1 sm:py-9">
                    {/* Card Vendedor */}
                    <CardSectionComponent
                        icon={mobileIcon}
                        titulo="O vendedor cria um anúncio detalhado"
                        descricao="O vendedor vai preencher os dados do veículo, fotos e valor desejado pelo seu veículo"></CardSectionComponent>

                    {/* Card Concessionária */}
                    <CardSectionComponent
                        icon={houseGroupIcon}
                        titulo="Concessionárias encontram o carro ideal"
                        descricao="Você não tem mais esforços aqui. Nós buscaremos as concessionárias com a melhor oferta que você desejar."></CardSectionComponent>

                    {/* Card Negociação */}
                    <CardSectionComponent
                        icon={checkedIcon}
                        titulo="Negociação mediada por nós"
                        descricao="Toda mediação entre os valores ofertados são mediados por nós, para sua total segurança e comodidade."></CardSectionComponent>
                </div>
                {/* Gallery */}
                <div className="flex justify-start items-start p-2 sm:justify-end sm:items-end gap-4 sm:gap-8 sm:p-4 sm:flex-1">
                    <div className="flex flex-col justify-start items-start gap-4">
                        <img alt="rectangle" className="w-full h-[202px] sm:h-[332px] rounded-[15px]" src={rectagle145Image} />
                        <img alt="rectangle" className="h-[110px] sm:h-[198px] rounded-[15px]" src={rectagle148Image} />
                    </div>
                    <div className="flex flex-col justify-start items-start gap-4">
                        <img alt="rectangle" className="h-[110px] sm:h-[198px] rounded-[15px]" src={rectagle147Image} />
                        <img alt="rectangle" className="h-[202px] sm:h-[332px] rounded-[15px]" src={rectagle146Image} />
                    </div>
                </div>
            </div>
        </div>
    );
}